<template>
  <div id="home">
    <div class="gr-video-bg">
      <video  autoplay muted loop id="myVideo">
        <source src="https://joy.videvo.net/videvo_files/video/free/2014-12/large_watermarked/Office_Stapler_Red_with_Paper_preview.mp4" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>

    <section>
      <v-container>
        <v-row class="text-center">
          <v-col cols="12">

          </v-col>
        </v-row>
      </v-container>
    </section>

    <Footer/>
  </div>

</template>

<script>
  import Footer from "@/components/FooterEsp";
  export default {
    name: 'PageEmailSignatures',
    data: () => ({
    }),
    components: {Footer},
  }
</script>
